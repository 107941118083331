import React from 'react';
import styles from './Vision.css';
import {useRef, useEffect, useState} from 'react';


function Vision() {
    const videoRef = useRef(null);
    const photoRef = useRef(null);
    const [hasPhoto, setHasPhoto] = useState(false);


    const getVideo = () => {
        navigator.mediaDevices.getUserMedia({video: {facingMode: 'environment'}})
                                .then(stream => {
                                    let video = videoRef.current;
                                    video.srcObject = stream;
                                    video.play();
                                })
                                .catch(err => {
                                    console.err(err)
                                })
    }


    const takePhoto = () => {
        const width = 1280;
        const height = width / (16/9);

        let video = videoRef.current;
        let photo = photoRef.current;

        photo.width = width;
        photo.height = height;

        let ctx = photo.getContext('2d');
        ctx.drawImage(video, 0, 0, width, height);
        setHasPhoto(true);
    }

    function dataURItoBlob(dataURI) {
        var binary = atob(dataURI.split(',')[1]);
        var array = [];
        for(var i = 0; i < binary.length; i++) {
            array.push(binary.charCodeAt(i));
        }
        return new Blob([new Uint8Array(array)], {type: 'image/png'});
    }

    const closePhoto = () => {

        let photo = photoRef.current;
        let ctx = photo.getContext('2d');
        ctx.clearRect(0,0,photo.width, photo.height);

        setHasPhoto(false);
    }
    const uploadPhoto = (t) => {
        let video = videoRef.current;
        let photo = photoRef.current;

        let ctx = photo.getContext('2d');
        var image = photo.toDataURL("image/jpg");  // here is the most important part because if you dont replace you will get a DOM 18 exception.
        const up = dataURItoBlob(image)
        const timestamp = Date.now()
        t = t.toLowerCase().replace(/\s/g, '')
        //Storage.put(user.username + "/" + timestamp +" .txt", t)
        //Storage.put(user.username + "/" + timestamp +" .jpg", up)

        ctx.clearRect(0,0,photo.width, photo.height);

        setHasPhoto(false);

    }

    useEffect(() => {
        getVideo();
    }, [videoRef]);


        return (
        <div className="App">
            <div className="camera"></div>
                <video ref={videoRef}></video>
                <button onClick={takePhoto}>Capture</button>
            <div className={'result ' + (hasPhoto ? 'hasPhoto' : '')}>
                <canvas ref={photoRef}></canvas>

                <button style={{ position: "absolute", left: 20, bottom: 120}} onClick={(e) => uploadPhoto(e.target.innerText)}>Healthy</button>
                <button style={{ position: "absolute",left: 100, bottom: 120}} onClick={(e) => uploadPhoto(e.target.innerText)}>Mildew</button>
                <button style={{ position: "absolute",left: 180, bottom: 120}} onClick={(e) => uploadPhoto(e.target.innerText)}>Red spiders</button>
                <button style={{ position: "absolute",left: 20, bottom: 70}} onClick={(e) => uploadPhoto(e.target.innerText)}>Malnutrition</button>
                <button style={{ position: "absolute",left: 130, bottom: 70}} onClick={(e) => uploadPhoto(e.target.innerText)}>Gray mold</button>
                <button style={{ position: "absolute",left: 230, bottom: 70}} onClick={(e) => uploadPhoto(e.target.innerText)}>Misc</button>

                <button style={{ position: "absolute",left: 20, bottom: 20}} onClick={closePhoto}>Cancel</button>
            </div>
        </div>
    );
}

export default Vision;