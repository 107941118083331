import React from 'react';
import { useState } from 'react';

function NLP() {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Send the user's message to the chatbot API
    // and add the response to the messages array
    fetch('https://224z0s5rcg.execute-api.eu-north-1.amazonaws.com/test/chatbot', {
      method: 'POST',
      body: JSON.stringify({ 'text': input }),
      headers: {
        'Content-Type': 'application/json',
         'x-api-key': 'g8PqsKVIKH2uQ9vser6h73svQTGGFSf7W1tQhKRe'
      }
    })
      .then((response) => response.json())
      .then((response) => {
        setMessages([...messages, { sender: 'user', message: input }, { sender: 'chatbot', message: response.response }]);
        setInput('');
      });
  };

  return (
    <div className="chat-window">
      <div className="messages">
        {messages.map((message, index) => (
          <div key={index} className={`message ${message.sender}`}>
            {message.message}
          </div>
        ))}
      </div>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
        />
        <button type="submit">Send</button>
      </form>
    </div>
    );
}

export default NLP;