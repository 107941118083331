import React from "react";
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Box } from "@mui/system";
import { Container } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';



function Navbar() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

function generateMenuItem(route, label, handleCloseFunction) {
  return (
    <Link to={route} onClick={handleCloseFunction}>
      <MenuItem>{label}</MenuItem>
    </Link>
  );
}

  return (

    
    <Box>
      <Container sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '20px', textAlign: 'center'}}>
        <Box><h3>Substorm demo service</h3></Box>
        
        <Button
          color="primary"
          variant="text"
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <MenuIcon />
          Models
        </Button>
        
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          {generateMenuItem("/", "Home", handleClose)}
          {generateMenuItem("/NLP", "NLP", handleClose)}
          {generateMenuItem("/BiasAnalysis", "BiasAnalysis", handleClose)}
          {generateMenuItem("/vision", "Vision", handleClose)}
          {generateMenuItem("/alttext", "AltText", handleClose)}
          {generateMenuItem("/textanonymizer", "TextAnonymizer", handleClose)}
          {generateMenuItem("/chatbot", "ChatBot", handleClose)}
          {generateMenuItem("/PrintlerTest", "PrintlerTest", handleClose)}
          {generateMenuItem("/PrintlerTextSearch", "PrintlerTextSearch", handleClose)}
          {generateMenuItem("/PrintlerSimilarity", "PrintlerSimilarity", handleClose)}
          {generateMenuItem("/CucumberDecease", "CucumberDecease", handleClose)}
          {generateMenuItem("/orbigospeechtotext", "OrbigoSpeechToText", handleClose)}
          {generateMenuItem("/AloTest", "AloTest", handleClose)}


        </Menu>
      </Container>
    </Box>
  );
}

export default Navbar;