import React from 'react';
import { useState } from 'react';
import styles from './BiasAnalysis.css';

function BiasAnalysis() {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Send the user's message to the chatbot API
    // and add the response to the messages array
    fetch("https://224z0s5rcg.execute-api.eu-north-1.amazonaws.com/test/analysebias", {
      method: 'POST',
      body: JSON.stringify({ 'prompt': input , 'anonymize': true}),
      headers: {
        'Content-Type': 'application/json',
         'x-api-key': 'g8PqsKVIKH2uQ9vser6h73svQTGGFSf7W1tQhKRe'
      }
    })
      .then((response) => response.json())
      .then((response) => {
        setMessages([...messages, { sender: 'user', message: input }, { sender: 'bot', message: response.result }]);
        setInput('');
      });
  };

  return (
    <div id="chatbox" className="chat-window">
      <div id="messages" className="messages">
        {messages.map((message, index) => (
          <div key={index} className={`message ${message.sender}`}>
            {message.message}
          </div>
        ))}
      </div>
      <form onSubmit={handleSubmit}>
        <input
          id="input-container"
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
        />
        <button id="send" type="submit">Send</button>
      </form>
    </div>

    );
}

export default BiasAnalysis;