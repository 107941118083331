import React, { useState } from 'react';
import axios from 'axios';
import TextField from '@mui/material/TextField';

import { Box } from '@mui/material';
import { Container } from '@mui/material';
import { Grid } from '@mui/material';
import { Button } from '@mui/material';


const PrintlerTextSearch = () => {
  const baseUrl = "https://static.printler.com/media/photo/"; // Replace with your base URL
  const [query, setQuery] = useState('');
  const [imageNumbers, setNumbers] = useState([]);
  const [isChecked, setTranslateQuery] = useState([]);

  const handleQueryChange = (e) => {
    setQuery(e.target.value);
  };


  const handleCheckboxChange = (e) => {
    setTranslateQuery(!isChecked);
  };
  // Function to generate image URLs
  function generateImageUrls(baseURL, numbers) {
    return numbers.map((number) => `${baseURL}${number}_400x400.jpg`);
  }


  const handleSearch = async () => {


      // Send image to API
      //axios.post("http://0.0.0.0:8000/imagecaption", { image: base64Image},
      const t = isChecked?1:0
      axios.post("https://kbwgvl5jie.execute-api.eu-north-1.amazonaws.com/test/printler/search", { text: query, size: 50, translate: t, from:"Swedish"},
             {
                headers : {'Content-Type': 'application/json',
                            'x-api-key': 'g8PqsKVIKH2uQ9vser6h73svQTGGFSf7W1tQhKRe'}})
        .then((response) => {
          console.info(response);

          setNumbers(generateImageUrls(baseUrl, response.data.result));
        })
        .catch((error) => {
          console.error(error);
        });
  };

  const handleClick = async (buttonText) => {
          // Send image to API
      //axios.post("http://0.0.0.0:8000/imagecaption", { image: base64Image},
    axios.post("https://kbwgvl5jie.execute-api.eu-north-1.amazonaws.com/test/printler/search", { text: buttonText, size: 50},
             {
                headers : {'Content-Type': 'application/json',
                            'x-api-key': 'g8PqsKVIKH2uQ9vser6h73svQTGGFSf7W1tQhKRe'}})
        .then((response) => {
          console.info(response);

          setNumbers(generateImageUrls(baseUrl, response.data.result));
        })
        .catch((error) => {
          console.error(error);
        });
  };

  return (
    <Container sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', p: 0}}>
      
        <Container maxWidth="sm" sx={{display: 'flex', flexDirection: 'column', marginBottom: 5, p: 0}}>
          <TextField 
            color="primary"
            id="outlined-basic" label="Enter your search query" 
            variant="outlined" 
            value={query}
            onChange={handleQueryChange}
          />
          <Box sx={{display: "flex", justifyContent: 'flex-end', marginTop: "10px" }}>
            <Button
              variant="contained"
              onClick={handleSearch}
              width="100px"
            >
              Search
          </Button>
          </Box>
        
        <br></br>
        
        <br></br>
        <label>
            <input
              type="checkbox"
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
            Translate
        </label>
        <br></br>
          <Grid sx={{display: 'flex', flexDirection: 'row', gap: 1}}>
            <Button variant="outlined" size="small" onClick={() => handleClick('Art deco')}>Art deco</Button>
            <Button variant="outlined" size="small" onClick={() => handleClick('Pop art')}>Pop Art</Button>
            <Button variant="outlined" size="small" onClick={() => handleClick('Football')}>Football</Button>
            <Button variant="outlined" size="small" onClick={() => handleClick('Nature')}>Nature</Button>
            <Button variant="outlined" size="small" onClick={() => handleClick('Paintings')}>Paintings</Button>
          </Grid>
        </Container>
      
      
      <Container>
        {imageNumbers.map((imageUrl, index) => (
          <img key={index} src={imageUrl} alt={`Image ${index + 1}`} />
        ))}
      </Container>
      
    </Container>
    
  );
};

export default PrintlerTextSearch;