import './App.css';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import NLP from "./pages/NLP";
import Vision from "./pages/Vision";
import Home from "./pages/Home";
import NoPage from "./pages/NoPage";
import AltText from "./pages/AltText";
import BiasAnalysis from "./pages/BiasAnalysis";
import TextAnonymizer from "./pages/textanonymizer";
import ChatBot from "./pages/chatbot";
import PrintlerTest from "./pages/PrintlerTest";
import PrintlerTextSearch from "./pages/PrintlerTextSearch";
import PrintlerSimilarity from "./pages/PrintlerSimilarity";
import CucumberDecease from "./pages/CucumberDecease";
import OrbigoSpeechToText from "./pages/orbigospeechtotext";

import AloTest from "./pages/AloTest";

import { createTheme, ThemeProvider } from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      light: '#757ce8',
      main: '#020305',
      dark: '#002884',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
  },
});


function App({ signOut, user }) {
  return (
    
    <ThemeProvider theme={theme}>

      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="NLP" element={<NLP />} />
            <Route path="BiasAnalysis" element={<BiasAnalysis />} />
            <Route path="vision" element={<Vision />} />
            <Route path="alttext" element={<AltText />} />
            <Route path="textanonymizer" element={<TextAnonymizer />} />
            <Route path="chatbot" element={<ChatBot />} />
            <Route path="PrintlerTest" element={<PrintlerTest />} />
            <Route path="PrintlerTextSearch" element={<PrintlerTextSearch />} />
            <Route path="PrintlerSimilarity" element={<PrintlerSimilarity />} />
            <Route path="CucumberDecease" element={<CucumberDecease />} />
            <Route path="OrbigoSpeechToText" element={<OrbigoSpeechToText />} />
            <Route path="AloTest" element={<AloTest />} />
            <Route path="*" element={<NoPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
    
  );
}


//export default withAuthenticator(App);
export default App