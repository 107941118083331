import React, { useState } from 'react';
import axios from 'axios';

const AloTest = () => {
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [result, setResult] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setImage(file);
    setImageUrl(URL.createObjectURL(file));
  };

  const handleClick = async (buttonText) => {
          // Send image to API
      //axios.post("http://0.0.0.0:8000/imagecaption", { image: base64Image},
    axios.post("https://test-from-vscode-alo.azurewebsites.net/api/aloqastatus?", { article_no:1,
                                                                                    order_no:2,
                                                                                    sequence_no:3,
                                                                                    time:"2023-10-02T18:29:47Z",
                                                                                    status: "ok"},
             {
                headers : {'Content-Type': 'application/json'}})
        .then((response) => {
          console.info(response);
        })
        .catch((error) => {
          console.error(error);
        });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!image) {
      alert('No image selected');
      return;
    }

    // Convert image to base64
    const reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onload = () => {
      const base64Image = reader.result;

      // Send image to API
      //axios.post("http://0.0.0.0:8000/imagecaption", { image: base64Image},
      axios.post("https://224z0s5rcg.execute-api.eu-north-1.amazonaws.com/test/printlerclassify", { image: base64Image},
             {
                headers : {'Content-Type': 'application/json',
                            'x-api-key': 'g8PqsKVIKH2uQ9vser6h73svQTGGFSf7W1tQhKRe'}})
        .then((response) => {
          console.info(response);
          setResult(response.data.result);
        })
        .catch((error) => {
          console.error(error);
        });
    };
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <input type="file" onChange={handleFileChange} />
        <button type="submit">Submit</button>
      </form>
      <button onClick={() => handleClick('Status')}>Status</button>
      {imageUrl && <img src={imageUrl} alt="Selected image" />}
      {result && <div>{result}</div>}
    </div>
  );
};

export default AloTest;