import React from 'react';
import { useState } from 'react';
import styles from './textanonymizer.css';

function TextAnonymizer() {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Send the user's message to the chatbot API
    // and add the response to the messages array
    //fetch("https://224z0s5rcg.execute-api.eu-north-1.amazonaws.com/test/textanonymizer", {
    fetch("http://224z0s5rcg.execute-api.eu-north-1.amazonaws.com/test/textanonymizer", {
    //fetch("https://api.substorm.ai/test/textanonymizer", {
      method: 'POST',
      body: JSON.stringify({
        "text": input,
        "language": "sv",
        "recognizers": [
            {
                "recognizer": "LOCATION",
                "mode": "replace",
                "replacement": "<LOCATION>"
            },
            {
                "recognizer": "PERSON",
                "mode": "replace",
                "replacement": "<NAME>"
            },
            {
                "recognizer": "SWEDISH_SOCIAL_NUMBER",
                "mode": "replace",
                "replacement": "1111111-0000"
            },
            {
                "recognizer": "SWEDISH_COMPANY_NAME",
                "mode": "replace",
                "replacement": "COMPANY"
            },
            {
                "recognizer": "JOB_TITLE",
                "mode": "replace",
                "replacement": "job"
            },
            {
                "recognizer": "EMAIL_ADDRESS",
                "mode": "replace",
                "replacement": "<EMAIL>"
            },
            {
                "recognizer": "PHONE_NUMBER",
                "mode": "replace",
                "replacement": "<PHONE>"
            }
        ]
        }),
      headers: {
        'Content-Type': 'application/json',
         'x-api-key': 'g8PqsKVIKH2uQ9vser6h73svQTGGFSf7W1tQhKRe'
      }
    })
      .then((response) => response.json())
      .then((response) => {
        setMessages([...messages, { sender: 'user', message: input }, { sender: 'bot', message: response.text }]);
        setInput('');
      });
  };

  return (
    <div id="chatbox" className="chat-window">
      <div id="messages" className="messages">
        {messages.map((message, index) => (
          <div key={index} className={`message ${message.sender}`}>
            {message.message}
          </div>
        ))}
      </div>
      <form onSubmit={handleSubmit}>
        <input
          id="input-container"
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
        />
        <button id="send" type="submit">Send</button>
      </form>
    </div>

    );
}

export default TextAnonymizer;