import React, { useState } from 'react';
import axios from 'axios';


const PrintlerSimilarity = () => {
  const baseUrl = "https://static.printler.com/media/photo/"; // Replace with your base URL


  const [query, setQuery] = useState('');
  const [imageNumbers, setNumbers] = useState([]);
  const handleQueryChange = (e) => {
    setQuery(e.target.value);
  };



  // Function to generate image URLs
  function generateImageUrls(baseURL, numbers) {
    return numbers.map((number) => `${baseURL}${number}_400x400.jpg`);
  }
  const handleSearch = async () => {


      // Send image to API
      //axios.post("http://0.0.0.0:8000/imagecaption", { image: base64Image},
      axios.post("https://kbwgvl5jie.execute-api.eu-north-1.amazonaws.com/test/printler/similartext", { imageid: query},
             {
                headers : {'Content-Type': 'application/json',
                            'x-api-key': 'g8PqsKVIKH2uQ9vser6h73svQTGGFSf7W1tQhKRe'}})
        .then((response) => {
          console.info(response);

          setNumbers(generateImageUrls(baseUrl, response.data.result));
        })
        .catch((error) => {
          console.error(error);
        });
  };

  return (
    <div>
      <input
        type="text"
        placeholder="Enter your search query"
        value={query}
        onChange={handleQueryChange}
      />
      <button onClick={handleSearch}>Search</button>
      <div className="image-container">
        {imageNumbers.map((imageUrl, index) => (
          <img key={index} src={imageUrl} alt={`Image ${index + 1}`} />
        ))}
      </div>
    </div>
  );
};

export default PrintlerSimilarity;