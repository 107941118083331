import React from "react";
import {Outlet} from "react-router-dom";
import Navbar from "../Navbar";
import { Container } from "@mui/material";

const Layout = () => {
  return (
    <>
    <Navbar />
    <Container sx={{display: 'flex', justifyContent: 'center', paddingTop: '64px'}}>
      <Outlet />
    </Container>
    </>
  );
};

export default Layout;
