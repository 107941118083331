import React from 'react';
import { useState, useRef, useEffect } from 'react';
import styles from './chatbot.css';

function ChatBot() {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');

  const scrollingDivRef = useRef(null);

  // Scroll the div to the bottom whenever its content changes
  useEffect(() => {
    if (scrollingDivRef.current) {
      scrollingDivRef.current.scrollTop = scrollingDivRef.current.scrollHeight;
    }
  }, [messages.length]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Send the user's message to the chatbot API
    // and add the response to the messages array
    fetch("https://api.substorm.ai/ollama/api/chat", {
      method: 'POST',
      body: JSON.stringify({
                "model": "substorm_small_1_0:latest",
				"messages": [...messages, { role: 'user', content: input }],
				"stream": false}),
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': 'yLm3W2zAkj6FhmurDfK3h5JePNt8tcgnaroX4Xsx'
      }
    })
      .then((response) => response.json())
      .then((response) => {
        setMessages([...messages, { role: 'user', content: input }, { role: 'assistant', content: response.message.content }]);
        setInput('');
      });
  };

  return (
    <div id="chatbox" className="chat-window">
		<div id="messages" className="messages" ref={scrollingDivRef}>
        {messages.map((message, index) => (
          <div key={index} className={`message ${message.role}`}>
            {message.content}
          </div>
        ))}
      </div>
      <form onSubmit={handleSubmit}>
        <input
          id="input-container"
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
        />
        <button id="send" type="submit">Send</button>
      </form>
    </div>

    );
}

export default ChatBot;
