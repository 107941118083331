import React, { useState } from 'react';
import axios from 'axios'
import styles from './orbigospeechtotext.css';

function OrbigoSpeechToText() {
  const [audioBlob, setAudioBlob] = useState(null);
  const [apiResult, setApiResult] = useState('');

  const handleStartRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream);
      const audioChunks = [];

      mediaRecorder.ondataavailable = event => {
        audioChunks.push(event.data);
      };

      mediaRecorder.onstop = () => {
        const audioBlob = new Blob(audioChunks, { type: 'audio/mp3' });
        setAudioBlob(audioBlob);
      };

      mediaRecorder.start();
      setTimeout(() => {
        mediaRecorder.stop();
      }, 3000); // Record for 3 seconds
    } catch (error) {
      console.error('Error starting recording:', error);
    }
  };

  const handleSendToApi = async () => {
    if (!audioBlob) return;

    const reader = new FileReader();
    reader.readAsDataURL(audioBlob);
    reader.onload = () => {
    const base64Image = reader.result.split(',')[1];

    // Send image to API
    //axios.post("http://0.0.0.0:8000/imagecaption", { image: base64Image},
    axios.post("https://224z0s5rcg.execute-api.eu-north-1.amazonaws.com/test/orbigospeechtotext", { mp3: base64Image},
         {
            headers : {'Content-Type': 'application/json',
                        'x-api-key': 'g8PqsKVIKH2uQ9vser6h73svQTGGFSf7W1tQhKRe'}})
    .then((response) => {
      console.info(response);
      setApiResult(response.data.result);
    })
    .catch((error) => {
      console.error(error);
    });
    };

  };

  return (
    <div className="App">
      <h1>Audio Recorder and Sender</h1>
      <button onClick={handleStartRecording}>Start Recording</button>
      <button onClick={handleSendToApi}>Send to API</button>
      {apiResult && <p>API Result: {apiResult}</p>}
    </div>
  );
}


export default OrbigoSpeechToText;